<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button v-if="userHasPermission('apps_customers_edit')" @click="addCustomer" class="mr-4 sm:mb-0 mb-2">
              Add
            </vs-button>
            <vs-button v-if="userHasPermission('apps_customers_edit')" @click="manageCompanies" class="mr-4 sm:mb-0 mb-2">
              Manage Companies
            </vs-button>
            <vs-input
              class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
          </div>
        </div>

        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="customerData"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @selection-changed="onSelectionChanged"
          @grid-size-changed="onGridSizeChanged"
        >
        </ag-grid-vue>
        <vs-pagination :total="totalPages" :max="5" v-model="currentPage" />
      </vx-card>

      <!-- <vs-prompt
        title="Delete Customer"
        accept-text="Delete"
        @cancel="closeDeletePriceRuleDialog"
        @close="closeDeletePriceRuleDialog"
        @accept="deleteCustomer"
        :active.sync="showDeletePriceRulePopup"
      >
        <div class="vx-row mt-2 mb-4">
          <div class="vx-col w-full">
            <label class="text-sm"
              >Assign Replacement PriceRule to Users:</label
            >
            <v-select
              v-model="selectedReplacementPriceRule"
              :options="replacementPriceRuleOptions"
            />
          </div>
        </div>
      </vs-prompt> -->
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererLink from "./cell-renderers/CellRendererLink.vue";
import CellRendererActions from "./cell-renderers/CellRendererActions.vue";
import customerStore from "./customerStore";
import helper from "./customerHelper";
import Vue from "vue";
import { format } from 'date-fns'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    AgGridVue,
    CellRendererLink,
    CellRendererActions,
  },
  data() {
    return {      
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererLink,
        CellRendererActions,
      },
      searchQuery: "",
      showDeletePriceRulePopup: false,
      selectedReplacementPriceRule: {},
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    customerData() {       
      return this.$store.state.customerStore.customers;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    preferedDateFormat() {      
      return this.$store.state.accountStore.generalSettings.dateFormat;
    },
    titleOptions () {
      return this.$store.state.lookups.titleOptions
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
    
  },
  created() {
    if (!customerStore.isRegistered) {
      this.$store.registerModule("customerStore", customerStore);
      customerStore.isRegistered = true;
    }
    
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.$route.params.applicationId)) ) {
      this.$router.push('/error-404')
    }

    //#region fetch required data from api and store in state
    this.$vs.loading();
    this.$store
      .dispatch(
        "customerStore/fetchCustomers",
        this.$route.params.applicationId
      )
      .catch((err) => console.error(err))
      .finally(() => this.$vs.loading.close());
    //#endregion
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs = [
      {
        headerName: "Actions",
        width: 150,
        sortable: false,
        suppressSizeToFit: false,
        cellRendererFramework: Vue.extend(CellRendererActions),
      },
      { headerName: "ID", width: 100, field: "customerId", hide: false },
      {
        headerName: "Name",        
        valueGetter : this.getFullName,
        sortable: true,
        suppressSizeToFit: false,
      },
      {
        headerName: "Email",
        field: "email",
        sortable: true,        
        suppressSizeToFit: false,
      },      
      {
        headerName: "Status",
        field: "isActive",
        sortable: true,
        //valueFormatter : this.activeFormatter,
        valueGetter : this.activeFormatter,
        suppressSizeToFit: false,
      },
      { 
        headerName: 'Created Date',
        field: 'createdDate',
        sortable: true,
        width: 180, 
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      }
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true,
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    dateFormatter(date) {      
      return helper.dateFormatter.date(date.value,this.preferedDateFormat);
    },
    activeFormatter(params){
      return params.data.isActive == 1 ? 'Active' : 'Inactive';
    },
    
    getFullName(params){
      let title = this.titleOptions.find(x => x.code === params.data.title)
      let fName = params.data.firstName == null ? "" : params.data.firstName;
      let lName = params.data.lastName == null ? "" : params.data.lastName;
      let fullNameNoTitle = fName + " " +  lName;
      if(title) {
        return title.label + " " + fullNameNoTitle
      }
      return fullNameNoTitle
    },    
    addCustomer() {
      this.$router.push({
        name: "customer-addition",
        params: { applicationId: this.$route.params.applicationId },
      });
    },
    manageCompanies () {
      this.$router.push({
        name: "company-list",
        params: { applicationId: this.$route.params.applicationId },
      });
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
     onGridSizeChanged(params) {
      console.log(params.clientWidth);
      if (params.clientWidth > 500) {
        this.gridApi.sizeColumnsToFit();
      } else {
        this.columnDefs.forEach(x => {
          x.width = 300;
        });
        this.gridApi.setColumnDefs(this.columnDefs);
      }
    },
    onGridReady(params) {},
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
  },
};
</script>

<style>
</style>
