<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon
      title="View Details"
      icon="EyeIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="displayRecord"
    />
    <feather-icon
      v-if="userHasPermission('apps_customers_edit,apps_customers_add')"
      title="Edit"
      icon="Edit3Icon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="editRecord"
    />
    <feather-icon
      title="Delete"
      icon="TrashIcon"
      svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
      @click="confirmDeleteRecord"
    />
    <vs-prompt
      title="Delete Customer"
      accept-text="Delete"
      @cancel="closeDeleteDialog"
      @close="closeDeleteDialog"
      @accept="deleteCustomer"
      :active.sync="showDeleteDialog"
    >
      <div class="vx-row mt-2 mb-4">
        <div class="vx-col">         
          <span 
            >Are you sure you want to delete customer?
              </span>
          
        </div>       
        </div>      
    </vs-prompt>
  </div>
</template>

<script>
import TpNotificationMixin from "@/components/travio-pro/TpNotificationMixin.vue";
import vSelect from "vue-select";
import _ from "lodash";
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';
export default {
  name: "CellRendererActions",
  components: {
    vSelect,
  },
  mixins: [TpNotificationMixin],
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    editRecord() {     
      this.$router.push({name: "customer-main-edit",
        params: {
          applicationId: this.$route.params.applicationId,
          customerId: this.params.data.customerId,
        },
      })
      .catch((error) => {
        console.error(error);
      });
    },
    displayRecord() {
      this.$router.push({name: "customer-main-view",
        params: {
          applicationId: this.$route.params.applicationId,
          customerId: this.params.data.customerId,
        },
      })
      .catch((error) => {
        console.error(error);
      });
    },
    confirmDeleteRecord() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    deleteCustomer() {
      const payload = {
         applicationId: this.$route.params.applicationId,
         customerId: this.params.data.customerId,
      };
      this.$vs.loading()
      this.$http.delete(`api/customersetting/${payload.applicationId}/customer/${payload.customerId}`)
      .then(response => {
        return this.$store.dispatch("customerStore/fetchCustomers",this.$route.params.applicationId)
          .then( res => this.$_notifySuccess("Successfully deleted customer"));
      })
      .catch(error => {
        this.$_notifyFailure(error.response.data)
      })
      .finally(() => this.$vs.loading.close())  
    },
  },
};
</script>
